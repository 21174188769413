import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Nasze usługi | Usługi firmy Voxelystik Cleaning Co.
			</title>
			<meta name={"description"} content={"Od kurzu do blasku - poznaj spektrum usług Voxelystik "} />
			<meta property={"og:title"} content={"Nasze usługi | Usługi firmy Voxelystik Cleaning Co."} />
			<meta property={"og:description"} content={"Od kurzu do blasku - poznaj spektrum usług Voxelystik "} />
			<meta property={"og:image"} content={"https://voxelystik.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voxelystik.com/img/190401.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voxelystik.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voxelystik.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voxelystik.com/img/190401.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://voxelystik.com/img/190401.png"} />
			<meta name={"msapplication-TileImage"} content={"https://voxelystik.com/img/190401.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 50% 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Nasze usługi
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="--dark">
				W Voxelystik Cleaning Co. oferujemy kompleksową gamę usług sprzątania, z których każda jest dostosowana do tego, aby tchnąć świeżość i czystość w każdy zakątek Twojej przestrzeni. Nasz zespół jest przygotowany do obsługi różnych środowisk z precyzją i troską.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://voxelystik.com/img/3.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Sprzątanie mieszkań
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						- Standardowe sprzątanie domów: Dokładne sprzątanie obejmujące wszystkie pomieszczenia, zapewniające wolne od kurzu, lśniące środowisko.
						<br /><br />
						- Głębokie czyszczenie: Intensywne czyszczenie, które wykracza poza powierzchnię, docierając do trudno dostępnych miejsc w celu kompleksowego czyszczenia.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://voxelystik.com/img/4.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Czyszczenie komercyjne
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						- Sprzątanie biur: Indywidualne rozwiązania zapewniające porządek, zdrowie i produktywność w miejscu pracy.
						<br /><br />
						- Sprzątanie powierzchni handlowych: Specjalistyczne usługi dla handlu detalicznego, zapewniające czystą, przyjazną przestrzeń dla klientów.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://voxelystik.com/img/5.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Usługi specjalistyczne
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						- Sprzątanie przy przeprowadzce: Szczegółowe sprzątanie przy zmianie nieruchomości, dzięki czemu przeprowadzka staje się czystszym i bezstresowym doświadczeniem.
						<br /><br />
						- Mycie okien: Krystalicznie czyste usługi mycia okien przy użyciu bezpiecznych metod czyszczenia bez smug.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://voxelystik.com/img/6.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Ekologiczne czyszczenie
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						- Ekologiczne rozwiązania czyszczące: Wykorzystanie przyjaznych dla środowiska produktów, które są skuteczne i bezpieczne dla wszystkich.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://voxelystik.com/img/7.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Dodatkowe oferty
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						- Niestandardowe plany sprzątania: Plany dostosowane do indywidualnych potrzeb i harmonogramów klientów.
						<br /><br />
						- Sprzątanie sezonowe: Specjalne pakiety na sezonowe dogłębne czyszczenie, utrzymujące świeżość przestrzeni przez cały rok.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://voxelystik.com/img/8.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Skontaktuj się z nami
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Skontaktuj się z dedykowanym zespołem wsparcia Voxelystik, aby dowiedzieć się więcej o naszych usługach lub zaplanować sprzątanie.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});